import React, { useEffect, useState } from "react";
import { useLocation } from "@reach/router";
import { graphql, useStaticQuery } from "gatsby";
import PropTypes from "prop-types";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Item from "../components/item";

import {
  Button,
  Hero,
  Link,
  // SectionHeader
} from "../components/common";

// import Tablet from "../components/tablet";

// import Search from "../assets/images/vectors/search.svg";

import "./search.css";
import { shuffle } from "../../server/helpers";
import { getStaticSEOImage } from "../helpers";

function HomePage({ location: propLocation }) {
  const [items, setItems] = useState(30);
  const [searchTerm, setSearchTerm] = useState("");
  const {
    allStrapiProduct: {
      edges: [
        {
          node: { data: products },
        },
      ],
    },
  } = useStaticQuery(graphql`
    query AllProducts {
      allStrapiProduct {
        edges {
          node {
            id
            data {
              attributes {
                Name
                Price
                Tags
                FeaturedImage {
                  data {
                    attributes {
                      localFile {
                        childImageSharp {
                          gatsbyImageData(placeholder: TRACED_SVG)
                        }
                      }
                      formats {
                        large {
                          url
                          name
                          height
                          width
                        }
                      }
                    }
                  }
                }
                URL
              }
            }
          }
        }
      }
    }
  `);

  let foundProducts = products;

  if (searchTerm) {
    foundProducts = products?.length
      ? products.filter((product) =>
          String(product.attributes.Name)
            .toLowerCase()
            .includes(String(searchTerm).toLowerCase())
        )
      : [];
  }

  const names = products?.map((product) => product.attributes.Name);
  const suffleNames = shuffle(names);
  let tags = [];
  products?.forEach((product) => {
    tags = [...tags, ...product.attributes.Tags.split(", ")];
  });
  const shuffleTags = shuffle(tags);

  const location = useLocation();
  useEffect(() => {
    const term = new URLSearchParams(location.search).get("q");

    setSearchTerm(term);
  }, [location.search]);

  return (
    <Layout>
      <SEO
        keywords={[`vackground`, `pattern`, `background`, `design`]}
        title="Search"
        image={getStaticSEOImage("search")}
        path={propLocation?.pathname}
      />

      <section className="dev-vg-search__search-container mt-6 mb-12 md:mt-16 md:mb-24">
        <Hero
          title={
            <>
              {searchTerm ? (
                <>
                  <span className="text-ui-gray-plus-1">
                    {foundProducts.length
                      ? `${foundProducts.length} Products`
                      : "No Product"}{" "}
                    for
                  </span>
                  <br />
                  &ldquo;{searchTerm}&rdquo;
                </>
              ) : (
                "Search product"
              )}
            </>
          }
          description={
            <>
              Search instead of &ldquo;
              <button
                onClick={() => setSearchTerm(suffleNames[0])}
                className="underline text-brand-primary"
                to="somehting"
              >
                {suffleNames[0]}
              </button>
              &rdquo;
            </>
          }
        >
          <div className="dev-vg-search__search mx-auto w-full">
            <div className="relative">
              <i className="vg-search absolute left-5 top-5" />
              <input
                onChange={(e) => setSearchTerm(e.target.value)}
                value={searchTerm}
                placeholder="japanese floral pattern"
                className="w-full px-6 py-4 pl-12 rounded-md border border-ui-gray-plus-1"
                style={{ backgroundColor: "transparent" }}
              />
            </div>
            {typeof window !== "undefined" && (
              <div className="mt-2 text-sm flex items-center justify-between">
                <div className="text-ui-gray-plus-3 font-medium leading-4 text-sm">
                  <span className="text-ui-gray-plus-2">Try searching:</span>{" "}
                  {[...new window.Set(shuffleTags)]
                    .slice(0, 6)
                    .map((tag, index, arr) => (
                      <React.Fragment key={index}>
                        <button
                          onClick={(e) => setSearchTerm(e.target.textContent)}
                        >
                          {tag}
                        </button>
                        {`${arr.length - 1 !== index ? ", " : ""}`}
                      </React.Fragment>
                    ))}
                </div>
              </div>
            )}
          </div>
        </Hero>
      </section>
      <section className="mb-32">
        <div className="dev-vg-g-product-section__items grid lg:grid-cols-3 md:grid-cols-2 grid-cols-2 gap-x-6 md:gap-x-8 gap-y-8 md:gap-y-16">
          {foundProducts.slice(0, items).map((product, index) => (
            <Item
              key={index}
              title={product.attributes.Name}
              price={product.attributes.Price}
              salePrice={product.attributes.SalePrice}
              featuredImage={
                product.attributes.FeaturedImage?.data?.attributes.localFile
              }
              url={product.attributes.URL}
            />
          ))}
        </div>
        {foundProducts.length > 30 && items < foundProducts.length && (
          <div className="mt-16 flex items-center justify-center">
            <Button
              onClick={() => {
                setItems(items + 30);
              }}
              iconRotate={90}
              icon="vg-arrow-right"
              text="Load More Products"
            />
          </div>
        )}
      </section>
      {/* <section className="mb-40">
        <SectionHeader
          art
          artVector={Search}
          title="Try These Popular Search"
        />
        <div className="flex gap-4 flex-wrap justify-center">
          <Tablet />
          <Tablet />
          <Tablet />
          <Tablet />
          <Tablet />
          <Tablet />
          <Tablet />
          <Tablet />
          <Tablet />
          <Tablet />
          <Tablet />
        </div>
      </section> */}
      <section className="mb-24">
        <div className="text-center">
          <h3
            className="text-ui-gray-plus-4 font-bold text-3xl leading-10"
            style={{ letterSpacing: "-1px" }}
          >
            Didn’t Find What You’re Looking For?
            <br />
            <Link to="/contact">Let Us Know!</Link>
          </h3>
        </div>
      </section>
    </Layout>
  );
}

HomePage.propTypes = {
  pageContext: PropTypes.object,
  location: PropTypes.object,
};

export default HomePage;
